@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
body {
    /*background-color: #2cc181;*/
    margin: 0;
    font-family: 'Lato', sans-serif;
    color: #000;
    min-height: 100%;
}

a {
    color: black;
}

.container {
    /*max-width: 900px;*/
    margin: 1rem 2rem;
}

.header {
    display: flex;
    justify-content: end;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
    background-color: gray;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);
    padding: 0 1.5rem;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: whitesmoke;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin: .5rem;
}

.form-group > label {
    color: whitesmoke;
    font-size: 1.25rem;
    margin-bottom: .25rem;
}

.btn {
    background-color: hsl(154, 63%, 46%);
    color: whitesmoke;
    padding: .5em 1em;
    border-radius: .3em;
    border: none;
    cursor: pointer;
}

.btn:hover {
    background-color: hsl(154, 63%, 20%);
}

.card-grid {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
}

.card {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: .25rem;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);
    background-color: whitesmoke;
    transform-style: preserve-3d;
    transition: 150ms;
    cursor: pointer;
    transform: perspective(1000px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0));
}

.card:hover {
    --translate-y: -3px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .6);
}

.card.flip {
    --rotate-y: 180deg;
}

.card .front {
    left: 0;
}

.card .front,
.card .back {
    position: absolute;
    padding: 1rem;
    backface-visibility: hidden;
}

.card .back {
    transform: rotateY(180deg);
}

.flashcard-options {
    margin-top: .5rem;
}

.flashcard-option {
    margin-top: .25rem;
    color: #555;
    font-size: 1rem;
}

.flashcard-option:first-child {
    margin-top: 0;
}

.crop {
    width: 275px;
    height: 200px;
    overflow: hidden;
}

.crop img {
    width: 475px;
    height: 300px;
    margin: -75px 0 0 -100px;
}